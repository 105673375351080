import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/forge/docs.wild.plus/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Link } from 'docz';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "code-linting"
    }}>{`Code Linting`}</h1>
    <p>{`These packages provide the recommended WILD's Eslint/Prettier configurations for different types of projects.`}</p>
    <ul>
      <li parentName="ul">
        <Link to="/code-linting/eslint-config-base" mdxType="Link">Eslint Base Config</Link>
        <div><small>WILD Eslint configuration for Javascript based projects - .js.</small></div>
      </li>
      <li parentName="ul">
        <Link to="/code-linting/eslint-config-typescript" mdxType="Link">Eslint Typescript Config</Link>
        <div><small>WILD Eslint configuration for Typescript based projects - .ts.</small></div>
      </li>
      <li parentName="ul">
        <Link to="/code-linting/eslint-config" mdxType="Link">Eslint React Config</Link>
        <div><small>WILD Eslint configuration for React based projects - .tsx and .jsx.</small></div>
      </li>
      <li parentName="ul">
        <Link to="/code-linting/prettier-config" mdxType="Link">Prettier config</Link>
        <div><small>WILD Prettier configuration.</small></div>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      